import './jquery.menu.js'
import * as breakpoints from '@js/util/breakpoints'

$(function () {
  let $nav = $('nav.main')
  let $menus = $nav.find('ul > li:has(> ul)')

  /*******************************
   *  Attach submenu toggle buttons
   ******************************/
  $menus.each(function () {
    let $menu = $(this)
    let $toggleButton = $('<button class="submenu-toggle"></button>')

    breakpoints.watch(
      'md',
      matches => $toggleButton.prop('disabled', matches),
      { immediate: true },
    )

    $toggleButton.click(event => {
      event.preventDefault()
      event.stopImmediatePropagation()

      $menu.toggleClass('submenu-active')
    })
    $menu.find('> a').append($toggleButton)
  })

  /*******************************
   *  Enable Hamburger Menu
   ******************************/
  let $header = $('header.main')

  $header.Menu()

  // Close hamburger menu when screen becomes too large (i.e. iPad rotate)
  breakpoints.watch('md', matches => {
    if (matches) {
      $header.Menu('close')
    }
  })

  /*******************************
   * Hover Submenu on Touch Devices
   *
   * First click on menu item with children opens submenu
   * and the second click on the item itself would follow its href.
   ******************************/
  let $topLevelSubmenuLinks = $nav.find('> ul > li.menu-item-has-children > a')
  let $menuItemsWithChildren = $nav.find('li.menu-item-has-children')

  $topLevelSubmenuLinks.on('touchstart', function (e) {
    if (!$(this).parent().is(':focus')) {
      e.preventDefault()
    }
  })

  $menuItemsWithChildren
    .attr('tabindex', '-1')
    .on('touchstart', function (e) {
      $(this).focus()
      $menuItemsWithChildren.removeClass('submenu-active')
      $(this).toggleClass('submenu-active')
      e.stopPropagation()
    })
    .on('blur', function (e) {
      if (!e.target.contains(e.relatedTarget)) {
        $(this).removeClass('submenu-active')
      }
    })
})
