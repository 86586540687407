// if ('IntersectionObserver' in window) {
//   // Ratio of the hero media to be scrolled out of view to hide the scroll indicator
//   const HIDE_INDICATOR_RATIO = 0.2
//   const threshold = 1 - HIDE_INDICATOR_RATIO
//
//   let observer = new IntersectionObserver(
//     entries => {
//       $(entries[0].target).toggleClass(
//         'show-scroll-indicator',
//         entries[0].intersectionRatio > threshold,
//       )
//     },
//     { threshold },
//   )
//
//   $(() => {
//     $('.with-scroll-indicator').each(function () {
//       observer.observe(this)
//     })
//   })
// }
