import breakpointsData from '@config/breakpoints.yml'

let breakpointsEntries = Object.entries(breakpointsData)

export const sizes = Object.fromEntries(
  breakpointsEntries.map(([name, breakpoint]) => [name, breakpoint.width]),
)

let breakpointsArray = breakpointsEntries.map(([name, breakpoint]) => ({
  name,
  width: breakpoint.width,
}))

let breakpointsWithMedia = breakpointsArray.map(breakpoint => ({
  ...breakpoint,
  media: matchMedia(`(min-width: ${breakpoint.width}px)`),
}))

let breakpointsByName = breakpointsArray.reduce(
  (carry, breakpoint, index) => ({
    ...carry,
    [breakpoint.name]: index,
  }),
  {},
)

function determineBreakpointIndex() {
  for (let i = breakpointsArray.length - 1; i > 0; i--) {
    if (breakpointsWithMedia[i].media.matches) {
      return i
    }
  }
  return 0
}

export function doesMatch(breakpointName) {
  if (!(breakpointName in breakpointsByName)) {
    return false
  }

  let requiredIndex = breakpointsByName[breakpointName]
  let actualIndex = determineBreakpointIndex()

  return actualIndex >= requiredIndex
}

export function watch(breakpointName, callback, { immediate = false } = {}) {
  if (!(breakpointName in breakpointsByName)) {
    console.error(`Unknown breakpoint name "${breakpointName}"`)
    return
  }

  function handler(event) {
    callback(event.matches)
  }

  let breakpoint = breakpointsWithMedia[breakpointsByName[breakpointName]]

  // Use 'addListener' instead of 'addEventListener' for IE11 compatibility
  breakpoint.media.addListener(handler)

  if (immediate) {
    callback(doesMatch(breakpointName))
  }

  return () => {
    breakpoint.media.removeListener(handler)
  }
}
